import { reqGetCode, reqPhoneCode, reqUserRegister, reqUserLogout, reqEditUserInfo, reqUserLogin, reqUserInfo, reqlogout } from '@/api';
import { setToken, getToken, removeToken } from '../utils/token';
import { setAccount, getAccount, removeAccount, getCartCount, setCartCount, removeCartCount } from '../utils/user';
const state = {
    code: '',
    token: getToken(),
    userInfo: {},
    account: getAccount(),
    cartCount: getCartCount(),
};
const actions = {
    //获取短信验证码
    async resPhoneCode({ commit }, phone) {
        let result = await reqPhoneCode(phone);
        // console.log(result);
        if (result.code == 200) {
            commit('GETCODE', result.data);
        }
        return result
    },
    //注册
    async userRegister({ commit }, user) {
        let result = await reqUserRegister(user);
        // console.log(result);
        if (result.code == 200) {
            return 'ok';
        } else {
            return Promise.reject(new Error('faile'));
        }
    },
    //登录业务
    async reqUserLogin({ commit }, user) {
        let result = await reqUserLogin(user);
        // console.log(result);
        //服务器下发token，用户唯一标识符(uuid)
        //将来经常通过带token找服务器要用户信息进行展示
        if (result.code === 200) {
            console.log('result: ', result);
            // 获取登录手机号
            commit('GETACCOUNT', user.account)
            setAccount(user.account)
            // 获取购物车数量
            commit('GETCARTCOUNT', result.data.data.cartCount)
            setCartCount(result.data.data.cartCount)
            commit('USERLOGIN', result.data.data.appToken);
            setToken(result.data.data.appToken); //持久化存储token

        }
        return result

    },
    //获取用户信息在首页展示【需要带着用户的token向服务器要用户信息】
    async resUserInfo({ commit }, account) {
        let result = await reqUserInfo(account);
        // console.log(result);
        if (result.code == 200) {
            commit('USERINFO', result.data);
            return result;
        } else {
            return Promise.reject(new Error('fail'));
        }
    },
    //修改用户信息
    async resEditUserInfo({ commit }, data) {
        let result = await reqEditUserInfo(data);
        // console.log(result);
        if (result.code == 200) {
            return result;
        } else {
            return Promise.reject(new Error('fail'));
        }
    },
    // 退出登录
    async resUserLogout({ commit }, account) {
        //只是向服务器发起一次请求，通知服务器清除token
        let result = await reqUserLogout(account);
        // console.log(result);
        //action里面不能操作state，提交mutation修改state
        if (result.code == 200) {
             commit('CLEAR', result.data);
            return result.data
        } else {
            return Promise.reject(new Error('falie'));
        }
    },
};
const mutations = {
    GETCARTCOUNT(state, cartCount) {
        state.cartCount = cartCount
    },
    GETCODE(state, code) {
        state.code = code;
    },
    USERLOGIN(state, token) {
        state.token = token;
    },
    USERINFO(state, userInfo) {
        state.userInfo = userInfo;
    },
    CLEAR(state) {
        //帮仓库中先关用户信息清空
        state.userInfo = {};
        state.token = '';
        state.account = ''
        state.cartCount = 0
        //本地存储数据清空
        removeToken();
        removeAccount()
        removeCartCount()
    },
    GETACCOUNT(state, account) {
        state.account = account;
    }
};
const getters = {
    userInfo(state) {
        return state.userInfo || {}
    },
    cartCount(state) {
        return state.cartCount || 0
    }
};
export default {
    state,
    actions,
    mutations,
    getters,
};
