<template>
  <div class="cate-nav width_1200_auto">
    <!-- 全部商品分类 -->
    <div
      class="cate-list"
      @mouseenter="showFirstList = true"
      @mouseleave="showFirstList = false"
    >
      <!-- 第一级分类 -->
      <div
        class="nav-side"
        :class="{ 'large-nav': !large, 'opacity-nav': opacity }"
        @mouseleave="panel = false"
      >
        <ul>
          <li
            v-for="(item, index) in cateList"
            @click="goGoodsList"
            :key="index"
            @mouseenter="showDetail(index)"
          >
            <span
              class="nav-side-item"
              :data-categoryName1="item.name"
              :data-categoryId1="item.id"
            >
              {{ item.name }}
            </span>
            <span v-for="(second, secIndex) in item.children" :key="secIndex">
              <span v-if="secIndex < 2"> / </span>
              <span
                :data-categoryName1="item.name"
                :data-categoryId1="item.id"
                :data-categoryName2="second.name"
                :data-categoryId2="second.id"
                class="nav-side-item"
                v-if="secIndex < 2"
                >{{ second.name }}</span
              >
            </span>
          </li>
        </ul>
      </div>

      <!-- 展开分类 -->
      <div
        class="detail-item-panel"
        :style="{ minHeight: large ? '470px' : '340px' }"
        v-show="panel"
        @mouseenter="panel = true"
        @mouseleave="panel = false"
      >
        <!-- 展开分类的一级分类 -->
        <div class="nav-detail-item" @click="goGoodsList">
          <template v-for="(item, index) in panelData">
            <span
              v-if="index < 8"
              :key="index"
              :data-categoryName1="parent.name"
              :data-categoryId1="parent.id"
              :data-categoryName2="item.name"
              :data-categoryId2="item.id"
            >
              {{ item.name }}
              <i class="el-icon-arrow-right"></i>
            </span>
          </template>
        </div>
        <ul>
          <li
            v-for="(items, index) in panelData"
            :key="index"
            class="detail-item-row"
            @click="goGoodsList"
          >
            <span
              class="detail-item-title"
              :data-categoryName1="parent.name"
              :data-categoryId1="parent.id"
              :data-categoryName2="items.name"
              :data-categoryId2="items.id"
            >
              {{ items.name }}
              <i class="el-icon-arrow-right"></i>
              <span class="glyphicon glyphicon-menu-right"></span>
            </span>
            <div>
              <span
                v-for="(item, subIndex) in items.children"
                @click="goGoodsList"
                :key="subIndex"
                class="detail-item"
                :data-categoryName1="parent.name"
                :data-categoryId1="parent.id"
                :data-categoryName2="items.name"
                :data-categoryId2="items.id"
                :data-categoryName3="item.name"
                :data-categoryId3="item.id"
                >{{ item.name }}</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="model-item" v-if="ShowOther">
      <Carousel></Carousel>
    </div>
  </div>
</template>
<script>
import Carousel from "@/components/nav/Carousel";
import { mapState } from "vuex";
export default {
  name: "CateNav",
  props: {
    ShowOther: { type: Boolean, default: true }
  },
  components: {
    Carousel,
  },
  data() {
    return {
      showNavBar: true,
      large: false,
      opacity: false,
      panel: false, // 二级分类展示
      panelData: [], // 二级分类数据
      form: {
        categoryId1: "",
        categoryName1: "",
        categoryId2: "",
        categoryName2: "",
        categoryId3: "",
        categoryName3: "",
      },
      parent: {},
    };
  },
  computed: {
    ...mapState({
      cateList: (state) => state.home.categoryList,
      userName: (state) => state.user.account,
    }),
  },
  mounted() {
    this.$store.dispatch("categoryList");
    if (this.$route.path != "/home") {
      this.showNavBar = false;
    }
  },
  beforeDestroy() {},
  methods: {
    showDetail(index) {
      // 展示全部分类
      this.panel = true;
      this.parent = this.cateList[index];
      this.panelData = this.parent.children;
    },
    goGoodsList(e) {
      let element = e.target;
      if (!element.dataset.categoryname1) return;
      let {
        categoryname1,
        categoryname2,
        categoryname3,
        categoryid1,
        categoryid2,
        categoryid3,
      } = element.dataset;
      //如果标签身上拥有categoryname一定是a标签
      //一级分类、二级分类、三级分类的a标签
      // 整理路由参数
      let location = { name: "search" };
      let category = [
        { categoryname1: categoryname1, category: categoryid1 },
        {
          categoryname2: categoryname2,
          category: categoryid2,
        },
        {
          categoryname3: categoryname3,
          category: categoryid3,
        },
      ];
      let query = {
        category: JSON.stringify(category),
      };
      // 合并路径和参数
      location.query = query;
      // 路由调转
      this.$router.push(location);
    },
  },
};
</script>
<style scoped lang="less">
// .model-item{
//     position: absolute;

// }
.width_1200_auto {
  width: 1200px;
  margin: auto;
}
.nav-lis:hover {
  color: #f31947 !important;
  cursor: pointer;
}
.nav-lis {
  width: 103px;
  text-align: center;
  position: relative;
}
.fixed-show {
  margin-top: 0 !important;
  > .nav-con {
    > .all-categories {
      align-items: center !important;
      height: 60px;
      display: inherit;
      justify-content: center;
      padding: 0 !important;
    }
  }
}
.colum {
  height: 14.7px;
  opacity: 1;
  border: 0.7px solid #cbc8c8;
  position: absolute;
  right: 0;
}
.cate-nav {
  position: relative;
  padding: 10px 0 20px 0;
}
/** 商品分类 */
.nav-con {
  height: 46px;
  margin: 0 auto;
  display: flex;
  .all-categories {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 37.4px;
    background: #ffffff;
    width: 225.2px;
    padding-top: 8.4px;
    line-height: 20px;
    border-bottom: none;
    font-size: 16.8px;
    font-weight: normal;
    color: #333333;
    letter-spacing: 0px;
  }
  .nav-item {
    width: 914px;
    height: 46px;
    border-radius: 10px;
    background: #ffffff;
    margin-left: 10px;
    line-height: 46px;
    overflow: hidden;
    list-style: none;
    // background-color: #eee;
    display: flex;
    li {
      float: left;
      font-size: 17px;
      font-weight: normal;
      color: #333333;

      &:hover {
        color: #f31947;
      }
    }
  }
}
// 分类列表
.cate-list {
  padding-top: 7px;
  margin: 0 auto;
  position: absolute;
  z-index: 1000;
}

.nav-side {
  padding-top: 10px;
  width: 263.2px;
  color: #666;
  float: left;
  overflow: hidden;

  border-radius: 10px;

  background-color: #fff;
  height: 340px;
  ////自动移滚动条样式
  //&::-webkit-scrollbar {
  //  width: 1px;
  //  height: 5px;
  //}
  //&::-webkit-scrollbar-thumb {
  //  border-radius: 1em;
  //  background-color: rgba(50, 50, 50, 0.3);
  //}
  //&::-webkit-scrollbar-track {
  //  border-radius: 1em;
  //  background-color: rgba(50, 50, 50, 0.1);
  //}
}
.large-nav {
  height: 470px;
  ul > li {
    line-height: 20px;
  }
}
.opacity-nav {
  background-color: rgba(0, 0, 0, 0.5);
}
.nav-side ul {
  width: 100%;
  list-style: none;
}
.nav-side li {
  padding: 0 0 16.2px 37.4px;
  font-size: 13px;
  line-height: 18px;
}
.nav-side-item:hover {
  cursor: pointer;
  color: #f31947;
}
/*显示商品详细信息*/
.detail-item-panel {
  width: 1000px;
  min-height: 340px;
  background-color: #fff;
  box-shadow: 0px 0px 15px #ccc;
  position: absolute;
  top: 0;
  left: 200px;
  z-index: 1000;
  padding: 15px;
}
.nav-detail-item {
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #eee;
}
.nav-detail-item span {
  padding: 6px;
  padding-left: 12px;
  margin-right: 15px;
  font-size: 12px;
  color: #333;
}
.nav-detail-item span:hover {
  background-color: #f31947;
  color: #fff;
}
.detail-item-panel li {
  line-height: 30px;
}
.detail-item-title {
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
  padding-right: 10px;
  width: 81px;
  text-align: right;
}
.detail-item-title:hover {
  color: #f31947;
}
.detail-item-row {
  display: flex;
  > div {
    flex: 1;
  }
}
.detail-item {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border-left: 1px solid #ccc;
  &:first-child {
    border: none;
    padding-left: 0;
  }
}
.detail-item:hover {
  color: #f31947;
}
</style>
