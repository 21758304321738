<template>
    <div class="model-carousel">
            <div class="nav-body clearfix">
                <!-- 侧边导航占位 -->
                <div class="nav-side"></div>
                <div class="nav-content">
                    <div style="width: 100%;height: 475px;border-radius: 10px;overflow: hidden">
                        <el-carousel :interval="3000" height="475px"  autoplay class="custom-carousel" indicator-positio="none" arrow="never">
                        <el-carousel-item v-for="(item, index) in bannerList" :key="index" style="height: 475px;cursor: pointer" >
                            <el-image :src="item.img" class="banner_img"  fit="fill"   style=" height: 475px" @click="getdetial(item)"/>
                        </el-carousel-item>
                    </el-carousel>
                    </div>
                </div>
                <div class="nav-right">
                    <div class="person-msg">
                        <div class="user-box" @click="entryControl({ path: 'home' })">
                            <img class="user-face" src="/images/buddha.png" alt/>
                            <div class="welcome"  v-if="userName">{{userName}}</div>
                            <div v-else> 欢迎来到聚灵云商城</div>
                        </div>
                        <div class="flex flex-a-c ">
                            <div class="btns" @click="$router.push('/login')" shape="circle"  v-if="!userName">登录</div>
                             <div class="btns" @click="layout" shape="circle" v-else>退出登录</div>
                        </div>
                    </div>
                    <div class="gray-line"></div>
                     <div class="sort-content">
                        <div class="icon-item" @click="entryControl(item)" :key="index" v-for="(item, index) in entranceList">
                           <div class="item-per">
                                <i :class="item.icon" style="font-size:22px;cursor: pointer"></i>
                                <div style="font-size:13px;cursor: pointer">
                                {{ item.label }}
                                </div>
                           </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
</template>

<script>
import {  Carousel,CarouselItem, Image, Button, Avatar } from "element-ui";
import {mapGetters, mapState} from "vuex";
export default {
  name: "modelCarousel",
  props: ["data",'show'],
  components: {
    "el-carousel": Carousel,
    "el-carousel-item": CarouselItem,
    "el-image": Image,
    "el-button": Button,
    "el-avatar": Avatar,
  },
  data() {
    return {
      userInfo: "", // 用户名
      entranceList: [

        {
          icon: "el-icon-shopping-cart-full",
          label: "购物车",
          path: "/shopcart"
        },
        {
          icon: "el-icon-notebook-2",
          label: "收货地址",
          path: "/center/Profile"
        },
        // {
        //   icon: "el-icon-place",
        //   label: "个人中心",
        //   path: "/home/MyAddress"
        // },

      ],
      bannerList: [
        {
          img:"https://balingv3.oss-cn-guangzhou.aliyuncs.com/ossUserFile/prod/1/828ccc9e2141369117fc0272c88e1de47b7dfa4e.jpg",
          id:'541937045405847553'
        },
        {
          img: "https://balingv3.oss-cn-guangzhou.aliyuncs.com/ossUserFile/prod/1/d5a007bae338f193677ce33bfbf75da24546bf07.jpg",
          id:'519637430580690945'
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userName: (state) => state.user.account,
    }),
  },
  methods: {
    // 快捷跳转中心
    entryControl(val) {
      console.log("val",val)
      let url = this.$router.push(val.path);

    },
    getdetial(row){
      this.$router.push(`/detail/${row.id}`);
    },
    async layout(){
      try {
        let data = await this.$store.dispatch("resUserLogout", {
          account: this.$store.state.user.account,
        });
        this.$message({
          message: data.msg,
          type: "success",
        });
        localStorage.removeItem('account')
        // 跳转到首页
        this.$router.push("/home");
      } catch (error) {
        console.log(error.message);
      }

    },
  },

};
</script>

<style scoped lang="less">
.flex-a-c{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

}
  .label {
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0px;

  color: #666666;
}

.swiper-img {
  overflow: hidden;
  width: 637px;
  height: 329.9px;
}

.icon-list {
  display: flex;
  flex-direction:row;
  width: 100%;
  height: 320px;
  justify-content: center;

}

// .icon-list:nth-last-of-type(1) {
//   margin-top: 20px;
// }

.hot-box {
  margin-top: 35px;
  width: 216px;

}

.hot-tag {
  margin-right: 6px;
  width: 36px;
  height: 18px;
  border-radius: 4px;
  opacity: 1;
  font-size: 12px;
  border: 1px solid #F31947;
  color: #F31947;
  text-align: center;
  line-height: 18px;
}



.model-carousel {
  width: 1200px;
  overflow: hidden;
}

.hover-pointer {
  //display: block;
  //width: 100%;
  //height: 100%;
  width: 637px;
  height: 329.9px;
  object-fit: cover;
  border-radius: 10px;


}

.welcome {
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0px;

  color: #333333;
}

.hr {
  width: 216px;
  height: 1px;
  border: 1px solid #E5E5E5;
}

.btns {
  margin-top: 10px;
  margin-bottom: 3px;
  width: 77px;
  height: 28px;
  border-radius: 14px;
  opacity: 1;
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0px;

  color: #FFFFFF;
  background: #F31947;
}

.sign-up {
  background: #F39519;
}

.avatar {
  margin-bottom: 13px;
}

.user-face {

  margin-bottom: 12px;
  width: 70px;
  height: 70px;
  border-radius: 50%;

}

/* 导航主体 */
.nav-body {
  width: 1200px;
  height: 340px;
  margin: 0px auto;
}

.nav-side {
  height: 334px;
  width: 263.2px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  float: left;
  padding: 0px;
  color: #fff;
  // background-color: #6e6568;


}

/** 导航内容 */
.nav-content {
  width: 737px;
  margin-left: 17px;
  margin-top: 10px;
  float: left;
  position: relative;
}

.nav-right {
  float: left;
  margin-top: 10px;
  width: 163.2px;
  margin-left: 17px;
  border-radius: 10px;
  background: #FFFFFF;
  height: 475px;
  align-content: center;

  .person-msg {

    cursor: pointer;
    .user-box{
      text-align: center;
      margin: auto;
    }
  }
    .gray-line {
        width: 116px;
        border: 1px solid #E5E5E5;
        margin: 20px 0px 13px 20px;
    }
    .sort-content{
        display: flex;
        flex-direction: row;
        flex-wrap:wrap!important;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .sort-content>div{
        height: 50px;
        margin: 10px;
        .item-per{
            text-align: center;
        }
    }
  .shop-msg {
    div {
      width: 100%;
      margin: 10px 27px;

      span {
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        margin-left: 5px;
      }

      span:nth-child(1) {
        @include content_color(#F31947);
        margin-left: 0;
      }

      span:nth-child(2) {
        font-weight: normal;
      }

      span:nth-child(3):hover {
        color: #F31947;
      }
    }

    ul {
      li {
        cursor: pointer;
        margin: 5px 0;
        color: #999395;
        width: 150px;
        font-size: 12px;

        &:hover {
          color: #F31947;
        }
      }
    }
  }
}
</style>
